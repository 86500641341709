
import * as aliyunRuntime$BLf9BhTpNk from '/home/debian/hanabi-live.com/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_ioredis@5.4.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxt/image/dist/runtime/providers/aliyun'
import * as indexRuntime$NPkWf8MYC6 from '/home/debian/hanabi-live.com/providers/index.ts'
import * as bilibiliRuntime$LmEZIiETnr from '/home/debian/hanabi-live.com/providers/bilibili.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "none": {},
    "default": {
      "modifiers": {
        "format": "webp",
        "quality": 95
      }
    }
  },
  "provider": "index",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aliyun']: { provider: aliyunRuntime$BLf9BhTpNk, defaults: {"baseURL":"https://static.hanabi-live.com/assets"} },
  ['index']: { provider: indexRuntime$NPkWf8MYC6, defaults: undefined },
  ['bilibili']: { provider: bilibiliRuntime$LmEZIiETnr, defaults: undefined }
}
        